import {graphql, withPrefix} from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

import {CaseHeader} from '../../../components/case_header';
import {WillAnimate} from '../../../components/content_animate';
import {Cta} from '../../../components/cta';
import {Layout} from '../../../components/layout';
import {ScrollTo} from '../../../components/scroll_to';

const locale = 'en';
const translatedPagePath = '/nl/portfolio/saas-product-keeping/';

export default ({data}: any) => (
    <Layout
        title="Our own product: Keeping – Intuitive time tracking – Showcase of our work"
        description="We develop our own SaaS product so you can keep track of hours worked. Read everything about our extensive time registration product Keeping."
        ogImage="keeping.png"
        translatedPagePath={translatedPagePath}
        navColor="white"
        locale={locale}>
        <CaseHeader
            urlBack="/en/portfolio/valuation-platform-taxapi/"
            titleBack="Case: Valuation platform TaXapi"
            urlForward="/en/portfolio/translation-platform-fairlingo/"
            titleForward="Case: Translation platform Fairlingo"
            locale={locale}
            translatedPagePath={translatedPagePath}>
            <Img className="hero__image" alt="Keeping" fluid={data.keeping.childImageSharp.fluid} critical />

            <div className="hero__content-wrapper">
                <div className="hero__content">
                    <h1>
                        {/* Make sure to have the correct spacing when br becomes invisible */}
                        Get your time <br />
                        organised
                    </h1>
                    <h2>With our SaaS product: Keeping</h2>
                </div>
            </div>
            <ScrollTo className="hero__scroll" target="/en/portfolio/saas-product-keeping/#from-hero" />
        </CaseHeader>

        <section className="section">
            {/* Case intro */}
            <article className="content">
                <div className="container">
                    <div className="content__display content__display--intro">
                        <h2>
                            To keep accurate track of all the hours we spend working on our projects, we developed our
                            own time tracker, and made it available as a <span className="highlight">SaaS product</span>
                            . Elegant in its simplicity, powerful in its capabilities.
                        </h2>
                    </div>
                </div>
            </article>

            {/* Case assignment */}
            <WillAnimate as="article" id="from-hero" className="content">
                <div className="container">
                    <div className="content__display content__display--right">
                        <h2>The idea</h2>
                    </div>

                    <div className="content__copy">
                        <p className="p-lead">
                            At Label305 we’ve always needed to track our time. As software engineers and consultants,
                            it’s important for us to keep accurate track of every minute we spend working. This enables
                            us to stay in control of our projects.
                        </p>
                        <p>
                            After using various time tracking solutions, we decided to create our own product for
                            tracking the hours we work. We used our expertise to develop a{' '}
                            <span className="highlight">SaaS-product</span> for the self-employed and SMEs. A product
                            that makes it possible to manage teams, projects, tasks, and most importantly, time in a{' '}
                            <span className="highlight">user-friendly</span> way.
                        </p>
                        <p>
                            Our aim is to enable every business that uses Keeping to achieve an increase in productivity
                            by gaining greater insight in the way their time is spent. The way to do this is to develop
                            a platform that works simply but efficiently, so that it really can become an integral part
                            of a business.
                        </p>
                        <ul className="list">
                            <li className="ion-md-arrow-dropright">Product management</li>
                            <li className="ion-md-arrow-dropright">UX and UI design</li>
                            <li className="ion-md-arrow-dropright">SaaS product development</li>
                            <li className="ion-md-arrow-dropright">Chrome extension and Firefox add-on</li>
                            <li className="ion-md-arrow-dropright">Unique integration with external services</li>
                        </ul>
                    </div>
                </div>
            </WillAnimate>

            <WillAnimate as="div" className="content">
                <div className="image">
                    <div className="image__content">
                        <Img alt="The mobiele applications of Keeping" fluid={data.keeping3d.childImageSharp.fluid} />
                    </div>
                    <div className="image__caption">
                        <span className="ion-md-arrow-dropright">
                            A clear interface. Simple things are kept simple, with more complex functionality easily
                            available.
                        </span>
                    </div>
                </div>
            </WillAnimate>

            {/* Case challenge */}
            <WillAnimate as="article" className="content">
                <div className="container">
                    <div className="content__display">
                        <h2>The challenge</h2>
                    </div>
                    <div className="content__copy">
                        <p className="p-lead">
                            Developing a SaaS product that any business can use requires a delicate balance between UI,
                            UX and technology. To achieve this, people with a range of different areas of expertise need
                            to work closely together.
                        </p>
                        <p>
                            The interface needs to be simple so that time tracking stays quick and{' '}
                            <span className="highlight">intuitive</span>, while at the same time there need to be enough
                            features to give meaning to the hours tracked in the interface.
                        </p>
                        <p>
                            To guarantee that the product will be widely used, it’s essential that it is available on{' '}
                            <span className="highlight">all the major platforms</span>. At the same time, no loss of
                            quality can be accepted whilst achieving this availability.
                        </p>
                        <p>
                            To be suitable for both self-employed people and SMEs, Keeping needed to be developed in a{' '}
                            <span className="highlight">scalable</span> way. A self-employed person, a team of 4 people
                            and a team of 500 people all need to be able to keep using time tracking in the same way{' '}
                            <span className="highlight">without any concessions</span>.
                        </p>
                    </div>
                </div>
            </WillAnimate>

            <WillAnimate as="div" className="content">
                <div className="video">
                    <div className="video__content">
                        <div className="video__browser">
                            <img alt="Browser graphic" src={withPrefix('/images/chrome_browser.svg')} />
                            <small>https://www.ellas-evenementen.keeping.nl/</small>
                            <video
                                width="100%"
                                playsinline
                                loop
                                muted
                                autoPlay
                                poster={withPrefix(
                                    '/images/keeping-urenregistratie-een-nieuwe-tijdregistratie-starten-placeholder.jpg',
                                )}>
                                <source
                                    src={withPrefix(
                                        '/video/keeping-urenregistratie-een-nieuwe-tijdregistratie-starten.mp4',
                                    )}
                                    type="video/mp4"
                                />
                            </video>
                        </div>
                    </div>
                    <div className="video__caption">
                        <span className="ion-md-arrow-dropright">
                            Easily start a time registration in{' '}
                            <a
                                className="link"
                                rel="follow"
                                href="https://keeping.nl/ontdek-urenregistratie"
                                title="Keeping time tracking (Dutch)"
                                target="_blank">
                                Keeping's
                            </a>{' '}
                            web application.
                        </span>
                    </div>
                </div>
            </WillAnimate>

            {/* Article idea and challenges description */}

            <WillAnimate as="article" className="content">
                <div className="container">
                    <div className="content__display">
                        <h2>
                            {/* Make sure to have the correct spacing when br becomes invisible */}
                            We make <br />
                            scalable <br />
                            software
                        </h2>
                    </div>

                    <div className="content__copy">
                        <p className="p-lead">
                            For our own product, Keeping, we do everything ourselves. There is no external client. We
                            fulfil the role of inventor, product manager, designer, developer and support provider.
                        </p>
                        <p>
                            By sticking to the same <span className="highlight">agile working</span> practices we use
                            for our clients, we achieve a streamlined development with on time deliveries. Scrum and
                            sprints form the basis for all development iterations of Keeping.
                        </p>
                        <p>
                            During development we focus on various parts of the software product in{' '}
                            <span className="highlight">parallel</span>. For example, we worked in parallel on the
                            browser extension and on an improved version of the Android application. This created{' '}
                            <span className="highlight">sub-projects</span> within the main Keeping project, each with
                            their own iterations.
                        </p>
                    </div>
                </div>
            </WillAnimate>

            <WillAnimate as="article" className="content">
                <div className="container">
                    <div className="content-group">
                        <div className="content-group__background content-group__background--right" />
                        <div className="content__image">
                            <div className="image">
                                <div className="image__content">
                                    <Img
                                        alt="Get insight with detailed reports"
                                        fluid={data.keepingReports.childImageSharp.fluid}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="content__copy">
                            <h2>Taking control of time and teams</h2>
                            <p>
                                To keep an overview of who is tracking which hours, each user tracks their hours
                                separately. Your Keeping environment <span className="highlight">can be adjusted</span>{' '}
                                to incorporate projects, customers and tasks as desired.
                            </p>
                            <p>
                                Hour overviews are created for each user, each project, each customer and each task.
                                This provides useful information that can form the basis for invoices and future job
                                estimates.
                            </p>
                            <p>
                                Our UX and UI engineers have delivered a{' '}
                                <span className="highlight">great interface</span> that any user will be familiar with
                                quickly.
                            </p>
                        </div>
                    </div>
                </div>
            </WillAnimate>

            <WillAnimate as="article" className="content">
                <div className="container">
                    <div className="content__image content__image--right">
                        <div className="image">
                            <div className="image__content">
                                <Img
                                    alt="The web extension of Keeping for Chrome"
                                    fluid={data.keepingExtension.childImageSharp.fluid}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="content__copy">
                        <h2>Extensions and add-ons</h2>
                        <p>
                            Chrome and Firefox are among the most commonly used browsers worldwide. Developing a SaaS
                            product like Keeping gave us the perfect opportunity to put our expertise with these
                            browsers to good use.
                        </p>
                        <p>
                            To improve user experience, we thought of a way of{' '}
                            <span className="highlight">integrating</span> part of our time tracking product into the
                            browser. This integration creates a unique experience for the user and makes important
                            actions quickly accessible and easier to carry out.
                        </p>
                        <p>
                            Time tracking can be started from any page in the browser, so the user no longer has to
                            visit the Keeping website. Everything is continually synchronised with Keeping so the time
                            tracking you input via the browser is immediately available to view within every
                            application.
                        </p>
                    </div>
                </div>
            </WillAnimate>

            <WillAnimate as="article" className="content">
                <div className="container">
                    <div className="content-group">
                        <div className="content-group__background content-group__background--right" />
                        <div className="content__image">
                            <div className="image">
                                <div className="image__content">
                                    <Img
                                        alt="Keeping mobile applications"
                                        fluid={data.keepingAndroid.childImageSharp.fluid}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="content__copy">
                            <h2>Apps for every situation</h2>
                            <p>
                                These days, it’s almost unimaginable for a product (particularly a SaaS product) not to
                                offer online options for smartphones. For Keeping, we developed two{' '}
                                <span className="highlight">native applications</span>, one for Android and one for iOS.
                            </p>
                            <p>
                                Mobile applications give a product the opportunity to{' '}
                                <span className="highlight">get closer</span> to the user and to be available for use
                                anytime, anywhere. Business people use the Keeping mobile applications at work, on the
                                way to an assignment or even on site in a different country.
                            </p>

                            <p>
                                The flexibility that the mobile applications provide is worth its weight in gold. At
                                least, that’s what our users tell us.
                            </p>

                            <p>
                                <em>
                                    Note: As of April 1, 2021 Label305 will no longer offer native app development, and
                                    Development of Keeping's mobile apps is outsourced to another Dutch party.
                                </em>
                            </p>
                        </div>
                    </div>
                </div>
            </WillAnimate>

            {/* Article integrations  */}

            <WillAnimate as="article" className="content">
                <div className="container">
                    <div className="content__display">
                        <h2>
                            {/* Make sure to have the correct spacing when br becomes invisible */}
                            Intagrations <br /> with <br /> Keeping
                        </h2>
                    </div>

                    <div className="content__copy">
                        <p className="p-lead">
                            Invoicing, project management and time registration go hand in hand thanks to the
                            integrations of Keeping.
                        </p>
                        <p>
                            Track your hours directly within your favorite{' '}
                            <span className="highlight">project management tools</span>. And invoice effort from Keeping
                            for a razor-sharp insight into the <span className="highlight">financial result</span> of
                            your work.
                        </p>
                        <p>
                            The integrations with external services serve to expand the availability of our product.
                            This ensures a wide-ranging but unique user experience. The key focus here is consistently
                            ensuring <span className="highlight">high-quality</span>, so that using our SaaS product
                            continues to be a pleasant experience.
                        </p>
                        <p>
                            For example, it’s important that every integration can be used in the same user-friendly
                            way. References and time linked to external services also need to be consistently processed
                            in Keeping in the same way.
                        </p>
                    </div>
                </div>
                <div className="container">
                    <div className="logos-grid logos-grid--tech">
                        <div className="logos-grid__background" />

                        <div className="logos-grid__row">
                            <div className="logos-grid__item">
                                <Img alt="Logo Asana" fluid={data.asana.childImageSharp.fluid} />
                                <span>Asana</span>
                            </div>
                            <div className="logos-grid__item">
                                <Img alt="Logo Basecamp" fluid={data.basecamp.childImageSharp.fluid} />
                                <span>Basecamp</span>
                            </div>
                            <div className="logos-grid__item">
                                <Img alt="Logo Exact online" fluid={data.exact.childImageSharp.fluid} />
                                <span>Exact online</span>
                            </div>
                            <div className="logos-grid__item">
                                <Img alt="Logo Atlassian Jira" fluid={data.jira.childImageSharp.fluid} />
                                <span>Atlassian Jira</span>
                            </div>
                        </div>

                        <div className="logos-grid__row">
                            <div className="logos-grid__item">
                                <Img alt="Logo Moneybird" fluid={data.moneybird.childImageSharp.fluid} />
                                <span>Moneybird</span>
                            </div>
                            <div className="logos-grid__item">
                                <Img alt="Logo Nmbrs" fluid={data.nmbrs.childImageSharp.fluid} />
                                <span>Nmbrs</span>
                            </div>
                            <div className="logos-grid__item">
                                <Img alt="Logo Todoist" fluid={data.todoist.childImageSharp.fluid} />
                                <span>Todoist</span>
                            </div>
                            <div className="logos-grid__item">
                                <Img alt="Logo Trello" fluid={data.trello.childImageSharp.fluid} />
                                <span>Trello</span>
                            </div>
                        </div>
                    </div>
                    <div className="logos-grid-caption">
                        <span className="ion-md-arrow-dropright">
                            A selection of integrations with Keeping. Check a full list of integrations on the{' '}
                            <a
                                className="link"
                                rel="follow"
                                href="https://keeping.nl/urenregistratie-integraties"
                                title="De website van Keeping"
                                target="_blank">
                                website
                            </a>
                            .
                        </span>
                    </div>
                </div>
            </WillAnimate>

            <WillAnimate as="article" className="content">
                <div className="container">
                    <div className="content__display content__display--right">
                        <h2>Support, marketing & communication</h2>
                    </div>
                    <div className="content__copy">
                        <p className="p-lead">
                            For a Saas product such as Keeping, a clear, findable website and good support are just as
                            important as the software itself.
                        </p>
                        <p>
                            In addition to the development of the various applications of Keeping, we set up the entire{' '}
                            <span className="highlight">marketing</span>,{''}
                            <span className="highlight">SEO optimized website</span>
                            {''}
                            and <span className="highlight">support team</span> ourselves.
                        </p>
                        <p>
                            To support Keeping users quickly, there is an extensive{' '}
                            <span className="highlight">knowledge base</span>
                            {''}
                            on the website with numerous supporting articles.
                        </p>
                        <p>
                            For a positive, first introduction to Keeping, we have chosen to combine a modern business
                            website with colorful and friendly graphics that have been developed in collaboration with
                            an illustrator.
                        </p>
                        <p>
                            The result: a clear and expressive website that perfectly presents our product and helps
                            Keeping users on their way quickly.
                        </p>
                    </div>
                </div>
            </WillAnimate>

            {/* Article image and role  */}

            <WillAnimate as="div" className="content">
                <div className="image">
                    <div className="image__content">
                        <Img
                            alt="Team aan het werk op kantoor met Keeping"
                            fluid={data.keepingWebsite.childImageSharp.fluid}
                        />
                    </div>
                    <div className="image__caption">
                        <span className="ion-md-arrow-dropright">
                            A clear, coherent application requires an efficient, appealing website. A clear overview of
                            all features and apps is available on the{' '}
                            <a
                                className="link"
                                rel="external nofollow"
                                href="https://keeping.nl/homepage"
                                title="The website of Keeping"
                                target="_blank">
                                Keeping-website
                            </a>
                            .
                        </span>
                    </div>
                </div>
            </WillAnimate>

            <WillAnimate as="article" className="content">
                <div className="container">
                    <div className="content__display">
                        <h2>
                            {/* Make sure to have the correct spacing when br becomes invisible */}
                            Technology <br />
                            behind <br />
                            Keeping
                        </h2>
                    </div>
                    <div className="content__copy">
                        <p className="p-lead">
                            To create Keeping, we needed to bring together a wide range of technical expertise.
                        </p>
                        <p>
                            The Keeping web application was developed on the solid foundation of the{' '}
                            <span className="highlight">Laravel-framework</span>. Alongside this, essential frequently
                            used interface components such as the ‘My hours’ overview were made with{' '}
                            <span className="highlight">React</span>, to enable a smooth interactive experience. Keeping
                            also offers browser extensions for Chrome and Firefox, written in TypeScript.
                        </p>
                        <p>
                            The mobile apps for Keeping were <span className="highlight">native written</span> in Kotlin
                            and Swift, for Android and iOS respectively. These apps make connection with the web
                            application via an <span className="highlight">API</span>, which can also be used by
                            external developers.
                        </p>
                        <p>
                            Finally, Keeping runs on redundantly implemented{' '}
                            <span className="highlight">cloud infrastructure</span> supplied by DigitalOcean. We
                            constantly <span className="highlight">monitor</span> the health of the software product
                            using external tooling such as: AppOptics, Bugsnag, Crashlytics and Pingdom. If a problem
                            arises, we are able to take action immediately.
                        </p>
                    </div>
                </div>

                <div className="container">
                    <div className="logos-grid logos-grid--tech">
                        <div className="logos-grid__background" />

                        <div className="logos-grid__row">
                            <div className="logos-grid__item">
                                <Img alt="Logo Laravel" fluid={data.laravel.childImageSharp.fluid} />
                                <span>Laravel</span>
                            </div>
                            <div className="logos-grid__item">
                                <Img alt="Logo PHP" fluid={data.php.childImageSharp.fluid} />
                                <span>PHP</span>
                            </div>
                            <div className="logos-grid__item">
                                <Img alt="Logo Mysql" fluid={data.mysql.childImageSharp.fluid} />
                                <span>MySQL</span>
                            </div>
                            <div className="logos-grid__item">
                                <Img alt="Logo Javascript" fluid={data.javascript.childImageSharp.fluid} />
                                <span>Javascript</span>
                            </div>
                        </div>

                        <div className="logos-grid__row">
                            <div className="logos-grid__item">
                                <Img alt="Logo React JS" fluid={data.react.childImageSharp.fluid} />
                                <span>React</span>
                            </div>
                            <div className="logos-grid__item">
                                <Img alt="Logo Android" fluid={data.android.childImageSharp.fluid} />
                                <span>Android</span>
                            </div>
                            <div className="logos-grid__item">
                                <Img alt="Logo iOS" fluid={data.ios.childImageSharp.fluid} />
                                <span>iOS</span>
                            </div>
                            <div className="logos-grid__item">
                                <Img alt="Logo Digital Ocean" fluid={data.digitalocean.childImageSharp.fluid} />
                                <span>Digital Ocean</span>
                            </div>
                        </div>
                    </div>
                </div>
            </WillAnimate>
            <Cta image={data.olav.childImageSharp.fixed} locale={locale} />
        </section>
    </Layout>
);

export const query = graphql`
    query {
        keeping: file(relativePath: {eq: "portfolio/keeping/keeping-barend-header.jpg"}) {
            ...fullscreenHero
        }

        olav: file(relativePath: {eq: "people/olav_2_zoom.jpg"}) {
            ...inlineAvatarImage
        }

        keeping3d: file(relativePath: {eq: "portfolio/keeping/3d-text-en.png"}) {
            ...fluid1600Width
        }
        keepingOverview: file(relativePath: {eq: "portfolio/keeping/keeping-overview.jpg"}) {
            ...fluid1200Width
        }
        keepingAndroid: file(relativePath: {eq: "portfolio/keeping/keeping-android.png"}) {
            ...fluid800Width
        }
        keepingTeams: file(relativePath: {eq: "portfolio/keeping/keeping-teams.jpg"}) {
            ...fluid800Width
        }
        keepingReports: file(relativePath: {eq: "portfolio/keeping/keeping-reports.png"}) {
            ...fluid800Width
        }
        keepingExtension: file(relativePath: {eq: "portfolio/keeping/keeping-extension.png"}) {
            ...fluid800Width
        }
        keepingIntegrations: file(relativePath: {eq: "portfolio/keeping/keeping-integrations.jpg"}) {
            ...fluid800Width
        }
        keepingTeam: file(relativePath: {eq: "portfolio/keeping/keeping-team.jpg"}) {
            ...fluid1200Width
        }
        keepingWebsite: file(relativePath: {eq: "portfolio/keeping/keeping-zzp.jpg"}) {
            ...fluid1200Width
        }

        android: file(relativePath: {eq: "logos/android.png"}) {
            ...logoImage
        }
        ios: file(relativePath: {eq: "logos/ios.png"}) {
            ...logoImage
        }
        laravel: file(relativePath: {eq: "logos/laravel.png"}) {
            ...logoImage
        }
        php: file(relativePath: {eq: "logos/php.png"}) {
            ...logoImage
        }
        mysql: file(relativePath: {eq: "logos/mysql.png"}) {
            ...logoImage
        }
        javascript: file(relativePath: {eq: "logos/javascript.png"}) {
            ...logoImage
        }
        react: file(relativePath: {eq: "logos/react.png"}) {
            ...logoImage
        }
        digitalocean: file(relativePath: {eq: "logos/digitalocean.png"}) {
            ...logoImage
        }

        asana: file(relativePath: {eq: "portfolio/keeping/integrations/asana.png"}) {
            ...logoImage
        }
        basecamp: file(relativePath: {eq: "portfolio/keeping/integrations/basecamp.png"}) {
            ...logoImage
        }
        eboekhouden: file(relativePath: {eq: "portfolio/keeping/integrations/eboekhouden.png"}) {
            ...logoImage
        }
        employes: file(relativePath: {eq: "portfolio/keeping/integrations/employes.png"}) {
            ...logoImage
        }
        exact: file(relativePath: {eq: "portfolio/keeping/integrations/exact.png"}) {
            ...logoImage
        }
        github: file(relativePath: {eq: "portfolio/keeping/integrations/github.png"}) {
            ...logoImage
        }
        jira: file(relativePath: {eq: "portfolio/keeping/integrations/jira.png"}) {
            ...logoImage
        }
        jortt: file(relativePath: {eq: "portfolio/keeping/integrations/jortt.png"}) {
            ...logoImage
        }
        moneybird: file(relativePath: {eq: "portfolio/keeping/integrations/moneybird.png"}) {
            ...logoImage
        }
        nmbrs: file(relativePath: {eq: "portfolio/keeping/integrations/nmbrs.png"}) {
            ...logoImage
        }
        random: file(relativePath: {eq: "portfolio/keeping/integrations/random.png"}) {
            ...logoImage
        }
        snelstart: file(relativePath: {eq: "portfolio/keeping/integrations/snelstart.png"}) {
            ...logoImage
        }
        todoist: file(relativePath: {eq: "portfolio/keeping/integrations/todoist.png"}) {
            ...logoImage
        }
        trello: file(relativePath: {eq: "portfolio/keeping/integrations/trello.png"}) {
            ...logoImage
        }
        twinfield: file(relativePath: {eq: "portfolio/keeping/integrations/twinfield.png"}) {
            ...logoImage
        }
    }
`;
