import {Link} from 'gatsby';
import React, {FunctionComponent, useLayoutEffect, useState} from 'react';

import {BodyNav} from './body_nav';
import {HamburgerContext} from './layout';
import {LocaleSwitch} from './locale_switch';

interface OwnProps {
    urlBack: string;
    urlForward: string;
    titleBack: string;
    titleForward: string;
    locale?: 'nl' | 'en';
    translatedPagePath: string;
}

export const CaseHeader: FunctionComponent<OwnProps> = ({
    urlBack,
    urlForward,
    titleBack,
    titleForward,
    children,
    locale,
    translatedPagePath,
}) => {
    const {isHamburgerOpen: hamburgerOpen, setHamburgerOpen} = React.useContext(HamburgerContext);

    return (
        <>
            <BodyNav
                urlBack={urlBack}
                urlForward={urlForward}
                titleBack={titleBack}
                titleForward={titleForward}
                locale={locale}
            />

            <section className="hero hero--case">
                {children}

                <div className="hero__ui-overlay">
                    <Link
                        to={locale === 'en' ? '/en/' : '/'}
                        title={locale === 'en' ? 'Back to the home page' : 'Terug naar de beginpagina'}
                        className="logo logo--ui-overlay"
                    />

                    <a
                        rel="noopener"
                        title={locale === 'en' ? 'Open or close menu' : 'Open of sluit menu'}
                        className="hamburger hamburger--ui-overlay"
                        onClick={() => setHamburgerOpen(!hamburgerOpen)}>
                        <span />
                        <span />
                        <span />
                    </a>

                    <BodyNav
                        className="body__nav--secondary"
                        urlBack={urlBack}
                        urlForward={urlForward}
                        titleBack={titleBack}
                        titleForward={titleForward}
                        locale={locale}
                    />

                    <LocaleSwitch
                        className="locale-switch--ui-overlay locale-switch--fixed"
                        navColor="#ffffff"
                        locale={locale}
                        targetUrl={translatedPagePath}
                    />
                </div>
            </section>
        </>
    );
};
