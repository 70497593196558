import {Link} from 'gatsby';
import React, {FunctionComponent, useLayoutEffect, useState} from 'react';

import {classNames} from '../support/classnames';

import {HamburgerContext} from './layout';

interface OwnProps {
    urlBack: string;
    urlForward: string;
    titleBack: string;
    titleForward: string;
    className?: string;
    locale?: 'en' | 'nl';
}

export const BodyNav: FunctionComponent<OwnProps> = ({
    urlBack,
    urlForward,
    titleBack,
    titleForward,
    className,
    locale,
}) => {
    const {isHamburgerOpen: hamburgerOpen} = React.useContext(HamburgerContext);

    return (
        <>
            <Link
                to={urlBack}
                title={titleBack}
                className={classNames('body__nav', 'body__nav--left', className, {
                    'body__nav--visible': !hamburgerOpen,
                })}>
                {locale === 'en' ? 'Previous case' : 'Vorige case'}
            </Link>
            <Link
                to={urlForward}
                title={titleForward}
                className={classNames('body__nav', 'body__nav--right', className, {
                    'body__nav--visible': !hamburgerOpen,
                })}>
                {locale === 'en' ? 'Next case' : 'Volgende case'}
            </Link>
        </>
    );
};
